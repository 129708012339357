import { useState, useEffect } from 'react';

import { ExpandMore, ExpandLess, Edit } from '@mui/icons-material/';
import { Box } from '@mui/system';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import BasicTable from '@revenue-solutions-inc/revxcoreui/material/controls/BasicTable';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DefaultDataTable from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/DefaultDataTable';
import HeaderColumn from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/HeaderColumn';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/datatables/TableCells/DateCell';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useGetUsers from 'hooks/useGetUsersWithRoles';
import useUpdateTitle from 'hooks/useUpdatetTitle';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { isMaxValueDate, toDate } from 'utils/date-util';

function ManageUserRoles(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useUpdateTitle(t('pages.userRolesList.title'));
  const [search, setSearch] = useState('');
  const accessToken = useGetAccessToken();
  const { data, refetch, isFetching, error } = useGetUsers(accessToken, search);

  const userColumns: Column[] = [
    {
      Header: <HeaderColumn localization={t('pages.userRolesList.edit')} />,
      id: 'userlink',
      Cell: (props: CellProps<Record<string, unknown>, unknown>) => (
        <Link
          to={{
            pathname: `/manageuserroles/edit/${props.row.original.userId}`,
          }}
        >
          <Edit />
        </Link>
      ),
    },
    {
      Header: <HeaderColumn localization={t('pages.userRolesList.name')} />,
      accessor: 'name',
    },
    {
      Header: <HeaderColumn localization={t('pages.userRolesList.email')} />,
      accessor: 'email',
    },
    {
      Header: <HeaderColumn localization={t('pages.userRolesList.roles')} />,
      accessor: 'userRole',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
        </span>
      ),
    },
  ];

  const userRoleColumns: Column[] = [
    {
      Header: <HeaderColumn localization={t('pages.userRolesList.roleName')} />,
      accessor: 'roleName',
    },
    {
      Header: (
        <HeaderColumn localization={t('pages.userRolesList.moduleName')} />
      ),
      accessor: 'moduleName',
    },
    {
      Header: (
        <HeaderColumn localization={t('pages.userRolesList.startDate')} />
      ),
      accessor: 'startDate',
      Cell: (row) => {
        const dateValue = toDate(row.value);
        return dateValue && !isMaxValueDate(dateValue) ? (
          <DateCell dateString={row.value} />
        ) : (
          t('pages.userRolesList.noDateSpecified')
        );
      },
    },
    {
      Header: <HeaderColumn localization={t('pages.userRolesList.endDate')} />,
      accessor: 'endDate',
      Cell: (row) => {
        const dateValue = toDate(row.value);
        return dateValue && !isMaxValueDate(dateValue) ? (
          <DateCell dateString={dateValue?.toDateString()} />
        ) : (
          t('pages.userRolesList.noDateSpecified')
        );
      },
    },
  ];

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [error, dispatch, t]);

  const handleSearch = () => {
    refetch();
  };

  return (
    <Box sx={{ width: '70em' }}>
      <Box sx={{ mb: '1em' }}>
        <Input
          id="manageuserroles-searchBox"
          value={search}
          onChange={(event) => {
            setSearch(event?.target.value);
          }}
          placeholder={t('pages.userRolesList.searchBy')}
          sx={{ width: '20em' }}
          helperText={
            search && search.length < 3
              ? 'Please enter at least 3 characters to search'
              : ''
          }
        />
        <Button
          id={'manageuserroles-searchBtn'}
          onClick={handleSearch}
          disabled={search.length < 3}
          sx={{ height: '35px' }}
        >
          {t('components.button.search')}
        </Button>
      </Box>
      {isFetching && <Loading />}
      {data && data.length > 0 && (
        <DefaultDataTable
          columns={userColumns}
          data-testid="user-roles-data"
          data={data}
          tableName={t('pages.userRolesList.users')}
          renderRowSubComponent={(row) => (
            <BasicTable columns={userRoleColumns} data={row.values.userRole} />
          )}
        />
      )}
      {data && data?.length == 0 && <NoResults />}
    </Box>
  );
}

export default ManageUserRoles;
